<template>
  <v-container fluid class="px-lg-10">
    <BaseTable
      ref="table"
      title="Piani Commerciali"
      :headers="headers"
      :actions="actions"
      module="plans"
    >
      <template v-slot:actions>
        <v-btn
          v-if="canUser('plans', 'create')"
          color="green"
          dark
          :to="{ name: 'plans_create' }"
        >
          Aggiungi piano
        </v-btn>
      </template>
      <template v-slot:item.active="{ item }">
        <v-chip v-if="item.active" color="success" small>ATTIVO</v-chip>
        <v-chip v-else color="gray" small>NON ATTIVO</v-chip>
      </template>
    </BaseTable>

    <v-dialog
      v-model="dialog"
      max-width="75%"
      :retain-focus="false"
      @click:outside="modalClose"
    >
      <v-card>
        <v-card-title>Modifica Dettagli</v-card-title>
        <v-card-text>
          <PlansForm :title="null" :edit="true" @submitted="onSubmitted" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import BaseTable from '@components/structure/base-table.vue'
import PlansForm from '@components/plans/PlansForm.vue'
import { authComputed } from '@state/helpers.js'

export default {
  name: 'PlansSearch',
  page: {
    title: 'Ricerca piani',
  },
  components: { PlansForm, BaseTable },
  data() {
    return {
      dialog: false,
      editMode: false,
      headers: [
        {
          text: 'Codice Identificativo',
          align: 'start',
          value: 'code',
        },
        {
          text: 'Nome Iniziativa',
          value: 'name',
        },
        {
          text: 'Canale',
          value: 'channel',
        },
        {
          text: 'Inserito',
          value: 'created_at',
        },
        {
          text: 'Attiva',
          value: 'active',
        },
      ],
      actions: [
        {
          label: 'Modifica',
          icon: 'mdi-pencil',
          color: 'primary',
          handler: this.openModal,
          onItemCondition: () => this.canUser('plans', 'update'),
        },
      ],
    }
  },
  computed: {
    ...authComputed,
  },
  methods: {
    ...mapActions('plans', ['getItems', 'update']),
    ...mapMutations('plans', {
      setCurrent: 'SET_CURRENT',
      resetCurrent: 'RESET_CURRENT',
    }),

    openModal(item) {
      const startingPoint = item.id ? item : {}
      // Understand if the modal was opened as editing or for a new sub
      const isEditing = !!startingPoint.id
      this.editMode = isEditing
      this.setCurrent(startingPoint)
      this.dialog = true
    },

    onSubmitted() {
      this.modalClose()
      this.$refs.table.refresh()
    },
    modalClose() {
      this.dialog = false
      this.editMode = false
      this.resetCurrent()
    },
  },
}
</script>
